<template>
  <WsRead
    :modelName="modelName"
    :id="$route.params.id"
    :localeMode="localeMode"
    :label="label"
    :fields="fields"
    :primary="pageSetting.primary"
    :secondary="pageSetting.secondary"
    titleKey="title"
  >
  </WsRead>
</template>

<script>
import model from "@/__vue2stone_cms/models/news";
export default {
  data: () => ({
    modelName: model.modelName,
    label: model.label,
    fields: model.fields,
    localeMode: false,
    pageSetting: {
      primary: [
        {
          type: "infoCard",
          floors: [
            {
              fields: ["content"],
            },
          ],
        },
      ],
      secondary: [
        {
          type: "infoCard",
          floors: [
            {
              title: "設定",
              fields: [
                "title",
                "description",
                "publish_at",
                "cover_image",
                "tags",
              ],
            },
          ],
        },
      ],
    },
  }),
};
</script>